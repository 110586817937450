@media screen and (max-width: 991px) {
  main {
    flex-direction: column;
  }

  aside {
    width: 100%;
    margin-left: 0;
  }

  .banner {
    flex-basis: auto;
    height: 300px;
  }
}

@media screen and (max-width: 768px) {
  header {
    background-color: @gray;

    &.header__wrap {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .header {
      &__logo {
        display: none;
      }
      &__burger {
        display: block;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(27deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-27deg);
              transform-origin: left;
            }
          }
        }
      }

      &__nav {
        position: absolute;
        top: -20px;
        left: 50px;
        background-color: @gray;
        padding: 10px 20px;
        max-width: 150px;
        font-size: 18px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;

        &.active {
          top: 10px;
          opacity: 1;
          visibility: visible;
        }
        ul {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          li {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  .news {
    &__item {
      flex-basis: 100%;

      .item {
        &__img {
          height: 300px;
        }
      }
    }
  }

  footer {
    background-color: @gray;

    .copyright {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 480px) {
  .content {
    img {
      &.left {
        float: none;
        margin-right: 0;
        width: 100%;
      }
      &.right {
        float: none;
        margin-left: 0;
        width: 100%;
      }
    }
    ul, ol {
      padding-left: 20px;
    }
  }
}