@import "fonts";

@gray: #F2F2F2;
@brown: #C6B58C;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 22px;
  font-weight: 400;
  font-family: Petrona, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.wrap {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}

header {
  &.header__wrap {
    margin-bottom: 20px;
  }
  .header {
    &__logo {
      display: block;
      height: 160px;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;

      img {
        height: 100%;
        width: auto;
      }
    }
    &__nav {
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        font-weight: 500;
        text-transform: uppercase;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__burger {
      display: none;
      position: relative;
      width: 30px;
      height: 17px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #000;
        border-radius: 2px;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          top: 14px;
        }
      }
    }
  }
}

main {
  flex: 1;

  &.wrap {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
  }
}

.banner {
  flex-basis: 100%;
  height: 400px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.news {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 -2% 20px;

  &__item {
    flex-basis: 46%;
    margin: 0 2% 20px;

    .item {
      &__img {
        width: 100%;
        height: 400px;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        margin-bottom: 12px;
      }
      &__title {
        display: block;
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 12px;
      }
      &__text {}
      &__link {
        color: @brown;
        text-decoration: underline;
        
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

aside {
  width: 350px;
  margin-left: 20px;
  background-color: @gray;
  padding: 12px 10px;
  margin-bottom: 20px;

  .sidebar-block {
    margin-bottom: 20px;

    &__title {
      display: block;
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      line-height: 1;
      padding-bottom: 8px;
      margin-bottom: 25px;
      border-bottom: 2px solid #000;
    }
    &__list {
      list-style: none;
      font-size: 28px;
      font-weight: 500;

      li {
        margin-bottom: 25px;
        line-height: 1.1;
        
        &.current {
          color: #C6B58C;
        }
      }
      a {
        &:hover {
          text-decoration: underline;
          color: #C6B58C;
        }
      }
    }
  }
}

.content {
  flex: 1;
  margin-bottom: 50px;

  & > * {
    margin-bottom: 10px;
  }
  h1, h2, h3 {
    font-weight: 700;
    text-align: center;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 24px;
  }

  a {
    color: @brown;
    text-decoration: underline;

    &:hover,
    &:visited {
      text-decoration: none;
    }
  }

  ul,ol {
    padding-left: 40px;
  }

  img {
    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
    &.center {
      display: block;
      max-width: 100%;
      margin: 0 auto 12px;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;

    td {
      padding: 8px 5px;
      border: 1px solid #000;
      text-align: center;
      line-height: 1.1;
    }
  }
}

footer {
  .copyright {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    background-color: @gray;
    padding: 8px 15px;
  }
}

@import "media";