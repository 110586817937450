@font-face {
  font-family: "Petrona";
  src: url("../fonts/Petrona-Regular.woff2") format("woff2"), url("../fonts/Petrona-Regular.woff") format("woff"), url("../fonts/Petrona-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 22px;
  font-weight: 400;
  font-family: Petrona, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
.wrap {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}
header.header__wrap {
  margin-bottom: 20px;
}
header .header__logo {
  display: block;
  height: 160px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
header .header__logo img {
  height: 100%;
  width: auto;
}
header .header__nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  font-weight: 500;
  text-transform: uppercase;
}
header .header__nav a:hover {
  text-decoration: underline;
}
header .header__burger {
  display: none;
  position: relative;
  width: 30px;
  height: 17px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #000;
  border-radius: 2px;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 7px;
}
header .header__burger span:nth-child(3) {
  top: 14px;
}
main {
  flex: 1;
}
main.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.banner {
  flex-basis: 100%;
  height: 400px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.news {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 -2% 20px;
}
.news__item {
  flex-basis: 46%;
  margin: 0 2% 20px;
}
.news__item .item__img {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  margin-bottom: 12px;
}
.news__item .item__title {
  display: block;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.news__item .item__link {
  color: #C6B58C;
  text-decoration: underline;
}
.news__item .item__link:hover {
  text-decoration: none;
}
aside {
  width: 350px;
  margin-left: 20px;
  background-color: #F2F2F2;
  padding: 12px 10px;
  margin-bottom: 20px;
}
aside .sidebar-block {
  margin-bottom: 20px;
}
aside .sidebar-block__title {
  display: block;
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  padding-bottom: 8px;
  margin-bottom: 25px;
  border-bottom: 2px solid #000;
}
aside .sidebar-block__list {
  list-style: none;
  font-size: 28px;
  font-weight: 500;
}
aside .sidebar-block__list li {
  margin-bottom: 25px;
  line-height: 1.1;
}
aside .sidebar-block__list li.current {
  color: #C6B58C;
}
aside .sidebar-block__list a:hover {
  text-decoration: underline;
  color: #C6B58C;
}
.content {
  flex: 1;
  margin-bottom: 50px;
}
.content > * {
  margin-bottom: 10px;
}
.content h1,
.content h2,
.content h3 {
  font-weight: 700;
  text-align: center;
}
.content h1 {
  font-size: 28px;
}
.content h2 {
  font-size: 26px;
}
.content h3 {
  font-size: 24px;
}
.content a {
  color: #C6B58C;
  text-decoration: underline;
}
.content a:hover,
.content a:visited {
  text-decoration: none;
}
.content ul,
.content ol {
  padding-left: 40px;
}
.content img.full {
  width: 100%;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.content img.center {
  display: block;
  max-width: 100%;
  margin: 0 auto 12px;
}
.content table {
  width: 100%;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
}
.content table td {
  padding: 8px 5px;
  border: 1px solid #000;
  text-align: center;
  line-height: 1.1;
}
footer .copyright {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  background-color: #F2F2F2;
  padding: 8px 15px;
}
@media screen and (max-width: 991px) {
  main {
    flex-direction: column;
  }
  aside {
    width: 100%;
    margin-left: 0;
  }
  .banner {
    flex-basis: auto;
    height: 300px;
  }
}
@media screen and (max-width: 768px) {
  header {
    background-color: #F2F2F2;
  }
  header.header__wrap {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  header .header__logo {
    display: none;
  }
  header .header__burger {
    display: block;
  }
  header .header__burger.active span:nth-child(1) {
    transform: rotate(27deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    transform: rotate(-27deg);
    transform-origin: left;
  }
  header .header__nav {
    position: absolute;
    top: -20px;
    left: 50px;
    background-color: #F2F2F2;
    padding: 10px 20px;
    max-width: 150px;
    font-size: 18px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  header .header__nav.active {
    top: 10px;
    opacity: 1;
    visibility: visible;
  }
  header .header__nav ul {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  header .header__nav ul li {
    margin-bottom: 15px;
  }
  .news__item {
    flex-basis: 100%;
  }
  .news__item .item__img {
    height: 300px;
  }
  footer {
    background-color: #F2F2F2;
  }
  footer .copyright {
    font-size: 12px;
  }
}
@media screen and (max-width: 480px) {
  .content img.left {
    float: none;
    margin-right: 0;
    width: 100%;
  }
  .content img.right {
    float: none;
    margin-left: 0;
    width: 100%;
  }
  .content ul,
  .content ol {
    padding-left: 20px;
  }
}
